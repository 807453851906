import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  vvcrUrl: string;

  constructor(private router: Router,
              @Inject(DOCUMENT) document: any) {
    this.vvcrUrl = environment.vvcrUrl;
  }

  ngOnInit(): void {
  }

  scrollToFeatures() {
    this.router.navigate(['/']);
    setTimeout(() => {
     let el = document.getElementById('features-anchor') as HTMLElement;
     el.scrollIntoView({behavior:"smooth"});
    }, 500);
  }

  goToLogin(){
    window.open(this.vvcrUrl + '/login', "_blank");
  }

  goToSupport(){
    window.open('https://support.vvcr.tv/', "_blank");
  }
  
  goToContact() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSc8sUQwkqJXUzJlbRCCVK-NDvMa39FtRsbaKrLK0oL-1J__0g/viewform?embedded=true', "_blank");
  }

}
