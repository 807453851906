<div class="header">
  <div class="logo" routerLink="/home"></div>
  <div class="btns-block">
    <div class="menu">
      <ul>
        <!-- <li routerLink="/home" routerLinkActive="active">Home</li> -->
        <!-- <li (click)="scrollToFeatures()">Features</li> -->
        <li routerLinkActive="active" (click)="goToSupport()">Support</li>
        <li routerLinkActive="active" (click)="goToLogin()">Login</li>
        <!-- <li routerLinkActive="active" (click)="goToSupport()">Request demo</li> -->
      </ul>
    </div>
    <!-- <button class="login"  (click)="goToSupport()">Support</button> -->
    <!-- <button class="login" (click)="goToLogin()">Login</button> -->
    <button class="demo"  (click)="goToContact()">Request demo</button>
  </div>
</div>
