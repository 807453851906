import { Component } from '@angular/core';
import { ImgLoaderService } from './services/img-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private imgLoaderService: ImgLoaderService) {
    imgLoaderService.loadImages();
  }

  onActivate() {
    window.scroll(0,0);
  }
}
