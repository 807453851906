<div class="banner">
  <div class="left-side">
    <span>Proudly made in Ukraine</span>
    <div class="gerb"></div>
  </div>
  <div class="rigth-side">
    <p>25% of all profits from our plans <strong>will go towards support of</strong><br> Ukraininan military and  humanitarian cases
      <a href="https://teaminua.medium.com/our-way-to-support-ukraine-during-the-war-team-in-ua-standwithukraine-a0b0f2edb665" target="_blank">Learn more!</a>
    </p>
  </div>
</div>
