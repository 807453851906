import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImgLoaderService {

  images = [
    '1x2.png',
    '1x2-active.png',
    '2x1.png',
    '2x1-active.png',
    '2x2.png',
    '2x2-active.png',
    '2x3.png',
    '2x3-active.png',
    '3x2.png',
    '3x2-active.png',
    '3x3.png',
    '3x3-active.png',
    '5x2.png',
    '5x2-active.png',
  ];

  loadImages() {
    for(let i = 0; i < this.images.length; i++){
      let img = new Image();
      img.src = './' + this.images[i];
    }
  }
}
